<template>
    <footer>
      <p>&copy; 2024 Charles' World. All rights reserved.</p>
    </footer>
  </template>
  

<script>
export default {
  name: 'AppFooter',
}
</script>

<style scoped>
footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 40px;
  text-align: center;
  background-color: #333;
  color: #fff;
}
</style>