<template>
    <div>
      <router-view></router-view> <!-- 动态内容 -->
    </div>
  </template>
  
  <script>
  export default {
    name: 'NoHeaderFooterLayout',
  };
  </script>
  
  <style scoped>
  /* 样式 */
  </style>
  