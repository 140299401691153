<template>
    <div>
      <AppArticle title="Hello! Here is Zhihe Ping">
        <p>I am an Unimelb Student, Master of IT.</p>
        <p>I like astronomy, physics, and computer science.</p>
        <p>This is my blog and welcome to my own world!</p>
      </AppArticle>
      <AppArticle title="TO DO">
        <p>Learn more about machine learning and Artificial Intelligence</p>
        <p>Develop the backend part of the blog</p>
        <p>Start to learn Quantum Computing</p>
        <p>Construct the basic knowledge about Quantum Mechanics</p>
        <p>Improve my language skills</p>
      </AppArticle>
      <button @click="goToArticles">Go to Articles</button>
    </div>
  </template>
  
  <script>
  import AppArticle from './Article.vue';
  
  export default {
    name: 'HomePage',
    components: {
      AppArticle,
    },
    methods: {
      goToArticles() {
        window.location.href = 'https://blog.charlesping.com';
      }
    }
  }
  </script>
  
  <style scoped>
  /* 样式 */
  </style>
  